<template>
  <div class="provider-tab-account">
    <!-- Media -->
    <b-row v-if="userData">
      <b-col
        v-if="!isAgent"
        cols="12"
        :offset="$store.getters['app/windowSize'] <= 767 ? 0 : 9"
        md="3"
      >
        <b-card
          no-body
          class="card-wallet-info"
        >
          <b-card-header class="pb-1">
            <h5 class="mb-0">
              {{ $t('Wallet Balance') }}
            </h5>
          </b-card-header>
          <b-card-body> {{ userData.currency_symbol }} {{ userData.wallet_balance }} </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Company Name')">
            <b-form-input
              v-model="userData.company_name"
              :class="{ 'is-invalid': !userData.company_name }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Service Category')">
            <multiselect
              v-model="userData.provider_service_id"
              label="name"
              track-by="id"
              :options="providerServiceOptions"
              :multiple="true"
              :close-on-select="false"
              :taggable="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('First Name')"
            label-for="firstName"
          >
            <b-form-input
              id="firstName"
              v-model="userData.first_name"
              :class="{ 'is-invalid': !userData.first_name }"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Last Name')"
            label-for="lastName"
          >
            <b-form-input
              id="lastName"
              v-model="userData.last_name"
              :class="{ 'is-invalid': !userData.last_name }"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              :class="{ 'is-invalid': !userData.email }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="user-select-location"
        >
          <b-form-group :label="$t('Update Location')">
            <GmapAutocomplete
              class="form-control"
              :value="userData.current_location"
              :class="{ 'is-invalid': !userData.current_location }"
              @place_changed="setPlace"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Country')"
            label-for="country"
          >
            <model-select
              v-model="userData.country_id"
              :class="{ 'is-invalid': !userData.country_id }"
              :isDisabled="isCountrySet"
              :options="
                countryInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))
              "
              @select="onCountryChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('State Name')">
            <model-select
              v-model="userData.state_id"
              :class="{ 'is-invalid': !userData.state_id }"
              :options="
                stateInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))
              "
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('City')"
            label-for="city"
          >
            <model-select
              v-model="userData.city_id"
              :class="{ 'is-invalid': !userData.city_id }"
              :options="
                cityInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Zipcode')">
            <b-form-input
              v-model="userData.zipcode"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Zone')">
            <b-form-input
              v-model="userData.zone_name"
              disabled
              type="text"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Mobile')"
            label-for="mobile"
          >
            <VuePhoneNumberInput
              ref="countryCodeRef"
              v-model="userData.mobile"
              :class="{ 'is-invalid': !userData.mobile }"
              placeholder="+91 0000000000"
              @update="onUpdate"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group :label="$t('Language')">
            <b-form-select
              v-model="userData.language"
              :options="$superApp.appConstants.languageList()"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="!isAgent"
          cols="12"
          md="3"
        >
          <b-form-group :label="$t('Price Range')">
            <v-select
              v-model="userData.price_range"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="priceRangeOptions"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="!isAgent"
          cols="12"
          md="3"
        >
          <b-form-group label="">
            <label
              class="form-extra-label"
            >{{ $t('Tax Percentage') }}(%) <span>({{ $t('Chargeable Tax percentage for bookings') }})</span></label>
            <b-form-input
              v-model="userData.provider_gst"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="!isAgent && false"
          cols="12"
          md="2"
        >
          <b-form-group :label="$t('Slug')">
            <b-form-input
              v-model="userData.slug"
              name="slug"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="2"
          class="my-auto"
        >
          <b-form-checkbox
            v-model="userData.freelancer"
            name="freelancer"
            switch
            inline
          >
            <span>Freelancer</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="12"
        >
          <figure>
            <legend class="text-uppercase">
              {{ $t('Business Information') }}
            </legend>
            <div class="row">
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group :label="$t('Registration Id')">
                  <b-form-input
                    v-model="userData.registration_id"
                    name="registration_id"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group :label="$t('Tax Id')">
                  <b-form-input
                    v-model="userData.tax_id"
                    name="tax_id"
                    type="text"
                  />
                </b-form-group>
              </b-col>
            </div>
          </figure>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="12"
          class="mb-1"
        >
          <figure>
            <legend class="text-uppercase">
              {{ $t('Payment Options') }}
            </legend>
            <div class="row">
              <div class="col-md-4 d-flex align-items-center mt-1">
                <b-form-checkbox
                  v-model="userData.cash"
                  name="cash"
                  inline
                >
                  <label class="form-extra-label">{{ $t('Cash') }} </label>
                </b-form-checkbox>
              </div>
              <div class="col-md-3 d-flex align-items-center mt-1">
                <b-form-checkbox
                  v-model="userData.card"
                  name="card"
                  inline
                >
                  <label class="form-extra-label">{{ $t('Card') }} </label>
                </b-form-checkbox>
              </div>
              <div class="col-md-5 d-flex align-items-center mt-1">
                <b-form-checkbox
                  v-model="userData.card_on_delivery"
                  name="delivery"
                  inline
                >
                  <label class="form-extra-label">{{ $t('Card On Delivery') }} </label>
                </b-form-checkbox>
              </div>
            </div>
          </figure>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="12"
          class="mb-1"
        >
          <figure>
            <legend class="text-uppercase">
              {{ $t('Service Options') }}
            </legend>
            <div class="row">
              <b-col
                v-if="!isAgent"
                cols="12"
                md="4"
                class="d-flex align-items-center mt-1"
              >
                <b-form-checkbox
                  v-model="userData.labelOnSite"
                  name="onsite"
                  inline
                >
                  <label class="form-extra-label">{{ $t('At user location') }} </label>
                </b-form-checkbox>
              </b-col>
              <b-col
                v-if="!isAgent"
                cols="12"
                md="4"
                class="d-flex align-items-center mt-1"
              >
                <b-form-checkbox
                  v-model="userData.labelOffSite"
                  name="onsite"
                  inline
                >
                  <label class="form-extra-label">{{ $t('At provider location') }} </label>
                </b-form-checkbox>
              </b-col>
            </div>
          </figure>
        </b-col>
        <b-col
          v-if="isAgent"
          cols="12"
          md="12"
          class="mb-1"
        >
          <figure>
            <legend class="text-uppercase">
              {{ $t('Timings') }}
            </legend>
            <agent-timings
              :readonly="true"
              :agent-timings="agentTimings"
            />
          </figure>
        </b-col>
        <b-col
          v-if="!isAgent"
          cols="12"
          md="12"
          class="mb-1"
        >
          <figure>
            <legend class="text-uppercase">
              {{ $t('Timings') }}
            </legend>
            <div class="row">
              <b-col
                v-if="!isAgent"
                cols="12"
                md="4"
                class="d-flex align-items-center mt-1"
              >
                <b-form-checkbox
                  v-model="userData.everyday"
                  name="everyday"
                  switch
                  inline
                  @change="$emit('opening-time-changed')"
                >
                  <label
                    class="form-extra-label"
                  >{{ $t('Provider Timing') }} <span>({{ $t('Set specific time for service start') }})</span></label>
                </b-form-checkbox>
              </b-col>
              <b-col
                v-if="userData.everydayTimesheet && userData.everydayTimesheet[0] && !isAgent"
                cols="12"
                md="2"
                class="d-flex align-items-center mt-1"
              >
                <b-form-checkbox
                  v-model="userData.everydayTimesheet[0].open_all_time"
                  name="open_all_time"
                  switch
                  inline
                >
                  <span>{{ $t('Accept request 24x7') }}</span>
                </b-form-checkbox>
              </b-col>
            </div>
          </figure>
        </b-col>
      </b-row>
      <div v-if="!showTimings && !isAgent">
        <b-row
          v-for="(item, index) of userData.everydayTimesheet"
          :key="index"
          :class="{ 'mt-2': index === 0 }"
        >
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="item.store_enabled"
              :name="item.day"
              switch
              inline
            >
              <span>{{ item.day === 'ALL' ? 'Everyday (Apply same timing for all days)' : item.day }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('Opens at')">
              <b-form-timepicker
                v-model="item.start_time"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('Closes at')">
              <b-form-timepicker
                v-model="item.end_time"
                locale="en"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="showTimings && !isAgent">
        <b-row
          v-for="(item, index) of userData.dailySheet"
          :key="index"
          :class="{ 'mt-2': index === 0 }"
        >
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="item.store_enabled"
              :name="item.day"
              switch
              inline
            >
              <span>{{ item.day }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('Opens at')">
              <b-form-timepicker
                v-model="item.start_time"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('Closes at')">
              <b-form-timepicker
                v-model="item.end_time"
                locale="en"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="userData.picture"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUserProfile"
    >
      {{ $t('Save Changes') }}
    </b-button>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormSelect, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardBody, BFormCheckbox, BFormTimepicker } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ModelSelect } from 'vue-search-select'
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import vSelect from 'vue-select'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useUserUi } from '@/views/users/useUser'
import { isEmpty } from '@/utilities'
import { useLoginUi } from '@/views/auth/useAuth'
import { useProviderUi } from '../useProvider'
import ImageInput from '../../users/shared/ImageInput.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import AgentTimings from '../shared/AgentTimings.vue'

export default {
  components: {
    BFormSelect,
    BButton,
    Multiselect,
    VuePhoneNumberInput,
    BRow,
    ModelSelect,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    ImageInput,
    BFormTimepicker,
    AgentTimings,
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
    showTimings: {
      type: Boolean,
      default: false,
    },
    countryInfo: {
      type: Array,
      default: null,
    },
    isCountrySet: {
      type: Boolean,
      default: false,
    },
    languageInfo: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      providerServiceOptions: [],
      phoneNumberPayload: null,
      cityInfo: [],
      agentTimings: {
        timings: [],
      },
      stateInfo: [],
      priceRangeOptions: [
        {
          label: 'Select Price Range',
          value: '',
        },
        {
          label: 'Not to Say',
          value: 'NOT_TO_SAY',
        },
        {
          label: 'In-Expensive',
          value: 'INEXPENSIVE',
        },
        {
          label: 'Moderate',
          value: 'MODERATE',
        },
        {
          label: 'Pricey',
          value: 'PRICEY',
        },
        {
          label: 'Ultra High',
          value: 'ULTRA_HIGH',
        },
      ],
      userSelectedPlace: null,
    }
  },
  computed: {
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
  },
  watch: {
    'userData.country_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.userData && this.userData.country_id) {
          this.onCountryChange()
        }
      },
    },
    'userData.state_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.userData && this.userData.state_id) {
          this.onStateChange()
        }
      },
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.picture = base64
    })

    return {
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
    const { providerServiceList } = useLoginUi()
    providerServiceList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.providerServiceOptions = data.responseData.map(x => ({
            name: x.service_category_name,
            id: x.id,
          }))
          if (this.userData.provider_service_id) {
            this.userData.provider_service_id = this.userData.provider_service_id
              .filter(x => this.providerServiceOptions.find(y => y.id === x.id))
              .map(x => ({
                id: x.id,
                name: this.providerServiceOptions.find(y => y.id === x.id).name,
              }))
          }
        }
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    isEmpty,
    onStateChange() {
      if (!this.userData.state_id) return
      this.cityInfo = []
      const { getCityByStateCountry } = useUserUi()
      showLoader()
      getCityByStateCountry(`country_id=${this.userData.country_id}&state_id=${this.userData.state_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cityInfo = data.responseData.map(x => ({
              label: x.city_name,
              value: x.id,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          if (this.userSelectedPlace && this.userSelectedPlace.setCity) {
            this.setCityFromApi()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      if (!this.userData.country_id) return
      this.cityInfo = []
      this.stateInfo = []
      const { getStateByCountry } = useUserUi()
      showLoader()
      getStateByCountry(this.userData.country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.stateInfo = data.responseData.map(x => ({
              label: x.state_name,
              value: x.id,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          if (this.userSelectedPlace && this.userSelectedPlace.setState) {
            this.setStateFromApi()
            this.userSelectedPlace.setState = false
          }
          if (this.userData.state_id) {
            this.onStateChange()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFile(payload) {
      if (this.userData) this.userData.picture = payload
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    async setPlace(place) {
      console.log(place.adr_address,place.address_components, 'v');
      this.userSelectedPlace = place
      if (Array.isArray(place.address_components)) {
        const components = place.address_components.filter(x => Array.isArray(x.types))
        const postalCode = components.find(x => x.types.includes('postal_code'))
        if (postalCode) {
          this.userData.zipcode = postalCode.long_name
        }
        const country = components.find(x => x.types.includes('country'))
        if (country) {
          const findCountry = this.countryInfo.find(x => x.label === country.long_name)
          if (findCountry) {
            this.userData.country_id = findCountry.value
            await this.onCountryChange()
          }
          const state = components.find(x => x.types.some(t => t === 'administrative_area_level_1'))
          if (state) {
            const findState = this.stateInfo.find(x => x.label === state.long_name)
            if (findState) {
              this.userData.state_id = findState.value
              await this.onStateChange()
            }
            const city = components.find(x => x.types.some(t => t === 'administrative_area_level_3'))
            if (city) {
              const findCity = this.cityInfo.find(x => x.label.toLowerCase() == city.long_name.toLowerCase())
              if (findCity) {
                this.userData.city_id = findCity.value
              }
            }
          }
        }
      }
      this.userData.current_location = place.formatted_address
    },
    setStateFromApi() {
      const components = this.userSelectedPlace.address_components.filter(x => Array.isArray(x.types))
      const state = components.find(x => x.types.includes('administrative_area_level_1'))
      if (state) {
        const findState = this.stateInfo.find(x => x.label === state.long_name)
        if (findState) {
          this.userData.state_id = findState.value
        }
      }
    },
    setCityFromApi() {
      const components = this.userSelectedPlace.address_components.filter(x => Array.isArray(x.types))
      const city = components.find(x => x.types.includes('locality'))
      if (city) {
        const findCity = this.cityInfo.find(x => x.label === city.long_name)
        if (findCity) {
          this.userData.city_id = findCity.value
        }
      }
    },
    updateUserProfile() {
      if (window.SuperApp.validations.anyErrorInModal(false)) {
        showDangerNotification(this, 'Please fill the required details')
        return
      }
      const { updateProviderProfileInfo } = useProviderUi()
      let updateFieldList = [
        'first_name',
        'last_name',
        'email',
        'mobile',
        'country_id',
        'city_id',
        'zipcode',
        'state_id',
        'language',
        'third_party_url',
        'facebook',
        'telegram',
        'whatsapp',
        'instagram',
        'linkedin',
        'twitter',
        'viber',
        'youtube',
        'messenger',
        'pintrest',
        'skype',
        'tiktok',
        'price_range',
        'country_code',
        'provider_gst',
        'slug',
        'tax_id',
        'registration_id',
        'company_name',
      ]
      if (this.isAgent) {
        updateFieldList = ['first_name', 'last_name', 'email', 'mobile', 'country_id', 'state_id', 'city_id', 'zipcode', 'language', 'country_code']
      }
      if (this.phoneNumberPayload) {
        this.userData.country_code = this.phoneNumberPayload.countryCallingCode
      }
      const formData = new FormData()
      if (!this.isAgent) {
        if (this.userData.third_party) {
          formData.append('third_party', 1)
        } else {
          formData.append('third_party', 0)
        }
        formData.append('cash', Number(this.userData.cash))
        formData.append('card', Number(this.userData.card))
        formData.append('card_on_delivery', Number(this.userData.card_on_delivery))
        formData.append('delivery', Number(this.userData.card_on_delivery))
        let onSiteNumber = 0
        if (this.userData.labelOnSite && this.userData.labelOffSite) {
          onSiteNumber = 2
        } else if (this.userData.labelOnSite) {
          onSiteNumber = 1
        } else if (this.userData.labelOffSite) {
          onSiteNumber = 0
        }
        formData.append('onsite', onSiteNumber)
      }
      if (this.userSelectedPlace) {
        formData.append('current_location', this.userData.current_location)
        formData.append('longitude', this.userSelectedPlace.geometry.location.lng())
        formData.append('latitude', this.userSelectedPlace.geometry.location.lat())
      } else {
        formData.append('current_location', this.userData.current_location)
        formData.append('longitude', this.userData.longitude)
        formData.append('latitude', this.userData.latitude)
      }
      if (document.querySelector('.user-profile-image') && document.querySelector('.user-profile-image').files && document.querySelector('.user-profile-image').files.length > 0) {
        formData.append('picture', document.querySelector('.user-profile-image').files[0])
      } else {
        formData.append('picture', this.userData.picture)
      }
      updateFieldList.map(x => formData.append(x, this.userData[x] ? this.userData[x] : ''))
      if (this.userData.everydayTimesheet.length && !this.isAgent) {
        formData.append('hours_opening[ALL]', this.userData.everydayTimesheet[0].start_time)
        formData.append('hours_closing[ALL]', this.userData.everydayTimesheet[0].end_time)
        formData.append('open_all_time', Number(this.userData.everydayTimesheet[0].open_all_time))
        if (!this.userData.everyday) {
          formData.append('everyday', null)
          if (this.userData.everydayTimesheet[0].store_enabled) formData.append('day[ALL]', 'ALL')
        }
      }
      if (!this.isAgent) {
        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
        days.forEach((x, i) => {
          if (this.userData.dailySheet[i]) {
            if (this.userData.everyday && this.userData.dailySheet[i].store_enabled) {
              formData.append(`day[${x}]`, this.userData.dailySheet[i].day)
            }
            formData.append(`hours_opening[${x}]`, this.userData.dailySheet[i].start_time)
            formData.append(`hours_closing[${x}]`, this.userData.dailySheet[i].end_time)
          }
        })
        if (Array.isArray(this.userData.provider_service_id)) {
          this.userData.provider_service_id.forEach(item => {
            formData.append('provider_service_id[]', item.id)
          })
        }
      }
      showLoader()
      updateProviderProfileInfo(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            window.SuperApp.getters.setUserInfo(JSON.stringify(data.responseData))
            eventBus.$emit('set-user-info-force')
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.provider-tab-account .user-select-location .col-form-label {
  padding: 0 0 2px 0;
  font-size: 13px;
}
</style>

<style lang="scss">
.provider-tab-account .b-form-timepicker .b-time-footer {
  display: block !important;
  position: static !important;
}
</style>
