<template>
  <component
    :is="userData === undefined ? 'div' : 'b-card'"
    class="user-profile"
  >
    <b-tabs
      v-if="userData"
      pills
      class="user-profile-info"
      v-model="activeTab"
    >
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/account.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <provider-tab-account
          ref="userEditTabAccount"
          :country-info="countryInfo"
          :isCountrySet="isCountrySet"
          :user-data="userData"
          :show-timings="showTimings"
          :language-info="languageInfo"
          class="mt-2 pt-75"
          @opening-time-changed="openingTimeChanged"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab v-if="!isAgent">
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/WeChat.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Social') }}</span>
        </template>
        <provider-tab-social
          :user-data="userData"
          class="mt-2 pt-75"
          @save-details="saveSocialDetails"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/reset_password.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Change Password') }}</span>
        </template>
        <user-edit-change-password class="mt-2 pt-75" />
      </b-tab>
      <b-tab v-if="!isAgent">
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/Payment_methods.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Bank Details') }}</span>
        </template>
        <provider-tab-payment-methods
          :bank-template="bankDetailsTemplate"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab v-if="!isAgent">
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/subscription.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Subscription') }}</span>
        </template>
        <SubscriptionTab 
          :packageFor="`PROVIDER`"
          class="mt-3"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BImg } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import UserEditChangePassword from '../users/profile/UserEditChangePassword.vue'
import ProviderTabAccount from './profile/ProviderTabAccount.vue'
import ProviderTabPaymentMethods from './profile/ProviderTabPaymentMethods.vue'
import { useUserUi } from '../users/useUser'
import { useProviderUi } from './useProvider'
import ProviderTabSocial from './profile/ProviderTabSocial.vue'
import SubscriptionTab from '@/views/shared/subscription/SubscriptionTab.vue'
import { mapActions, mapMutations } from 'vuex'
import { isEmpty } from '@/utilities'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    ProviderTabSocial,
    UserEditChangePassword,
    ProviderTabAccount,
    ProviderTabPaymentMethods,
    BImg,
    SubscriptionTab
  },
  data() {
    return {
      isCountrySet: false,
      showTimings: false,
      userData: null,
      activeTab: 0,
      countryInfo: [],
      cityInfo: [],
      languageInfo: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: 'Arabic',
          value: 'ar',
        },
      ],
      addressInfo: [],
      bankDetailsTemplate: [],
    }
  },
  computed: {
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
  },
  mounted() {
    if (this.$route.query.payment_intent_client_secret) {
      this.activeTab = 4
    }
    const provider = window.SuperApp.getters.userInfo()
    let uk = 'provider'+provider.id
    let checkout_id = localStorage.getItem(uk);
    const queryParams = this.$route.query
    if(queryParams.success && checkout_id){
      localStorage.removeItem(uk)
      showLoader()
      this.updateProviderSubscription(checkout_id).then(r => {
          showSuccessNotification(this, 'Subscription updated successfully')
          this.activeTab = 4
          hideLoader()
      }).catch(e => {
          showErrorNotification(this, e)
          hideLoader()
      })
    }else if(queryParams.error){
      showErrorNotification(this, 'Something went wrong while updating your subscription')
    }
    if(!isEmpty(isEmpty)){
      this.$router.replace({'query': null});
    }

    const { countryList } = useUserUi()
    const { providerProfile, providerBankDetails, getProviderAgentProfile } = useProviderUi()
    const self = this
    const callType = this.isAgent ? getProviderAgentProfile : providerProfile
    showLoader()
    callType()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          // eslint-disable-next-line no-param-reassign
          data.responseData.provider_service_id = []
          if (!data.responseData.state_id) {
            // eslint-disable-next-line no-param-reassign
            data.responseData.state_id = ''
          }
          if(!isEmpty(data?.responseData?.country_id)){
            this.isCountrySet = true
          }
          this.userData = data.responseData
          this.importFromUser(this.userData)
          this.userData.zone_name = ''
          if (this.userData.zone) {
            this.userData.zone_name = this.userData.zone.name
          }
          if (data.responseData.allowed_service_cats) {
            this.userData.provider_service_id = data.responseData.allowed_service_cats.map(x => ({
              id: x.category_id,
            }))
          }
          if (this.userData.provider_service_id && this.$refs.userEditTabAccount) {
            const { providerServiceOptions } = this.$refs.userEditTabAccount
            this.userData.provider_service_id = this.userData.provider_service_id
              .filter(x => providerServiceOptions.find(y => y.id === x.id))
              .map(x => ({
                id: x.id,
                name: providerServiceOptions.find(y => y.id === x.id).name,
              }))
          }
          this.userData.third_party = this.userData.third_party === 1
          this.userData.freelancer = this.userData.freelancer === 1
          this.userData.cash = this.userData.cash === 1
          this.userData.card = this.userData.card === 1
          this.userData.card_on_delivery = this.userData.card_on_delivery === 1
          if (this.userData.onsite === 2) {
            this.userData.labelOnSite = true
            this.userData.labelOffSite = true
          } else if (this.userData.onsite === 0) {
            this.userData.labelOnSite = false
            this.userData.labelOffSite = true
          } else if (this.userData.onsite === 1) {
            this.userData.labelOnSite = true
            this.userData.labelOffSite = false
          }
          if (this.userData.timings && this.userData.timings.length > 0 && this.userData.timings[0].day !== 'ALL') {
            this.userData.everyday = true
          } else {
            this.userData.everyday = false
          }
          this.userData.everydayTimesheet = [
            {
              day: 'ALL',
              end_time: this.getStoreTIme('ALL', 'end_time'),
              start_time: this.getStoreTIme('ALL', 'start_time'),
              store_enabled: true,
              open_all_time: this.userData.timings && this.userData.timings[0] && this.userData.timings[0].open_all_time === 1,
            },
          ]
          const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
          this.userData.dailySheet = dayKeys.map(x => ({
            day: x,
            end_time: this.getStoreTIme(x, 'end_time'),
            start_time: this.getStoreTIme(x, 'start_time'),
            store_enabled: this.isTimeEnabled('ALL') ? true : this.isTimeEnabled(x),
          }))
          this.openingTimeChanged()
          if (this.userData.latitude && this.userData.longitude) {
            setTimeout(() => {
              window.SuperApp.helper.getFormattedAddress(`${this.userData.latitude},${this.userData.longitude}`).then(res => {
                self.userData.current_location = window.SuperApp.helper.parsedAddress(res)
              })
            }, 250)
          }
          setTimeout(() => {
            self.$refs.userEditTabAccount.onCountryChange()
            if (self.$refs.userEditTabAccount && self.$refs.userEditTabAccount.$refs && self.$refs.userEditTabAccount.$refs.countryCodeRef) {
              window.SuperApp.helper.setCountryCode(self.$refs.userEditTabAccount.$refs.countryCodeRef, self.userData.country_code, self.userData.mobile)
            }
            if (this.isAgent) {
              this.$refs.userEditTabAccount.agentTimings = {
                timings: dayKeys.map(x => ({
                  day: x,
                  end_time: this.getStoreTIme(x, 'end_time'),
                  start_time: this.getStoreTIme(x, 'start_time'),
                  agent_enabled: this.isTimeEnabled('ALL') ? true : this.isTimeEnabled(x),
                })),
              }
            }
          }, 250)
          hideLoader()
        }
      })
      .catch(error => {
        console.log(error, 'error');
        showErrorNotification(this, error)
      })
    if (!this.isAgent) {
      providerBankDetails()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.bankDetailsTemplate = data.responseData
            this.bankDetailsTemplate.forEach(item => {
              if (!item.bankdetails) {
                // eslint-disable-next-line no-param-reassign
                item.bankdetails = {
                  keyvalue: '',
                  id: '',
                }
              }
            })
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    }
    countryList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.countryInfo = data.responseData.map(x => ({
            label: x.country_name,
            value: x.id,
          }))
        }
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    ...mapActions('subscription', [
      'updateProviderSubscription'
    ]),
    ...mapMutations('subscription', ['importFromUser']),
    saveSocialDetails() {
      if (this.$refs.userEditTabAccount) {
        this.$refs.userEditTabAccount.updateUserProfile()
      }
    },
    getStoreTIme(key, tag) {
      return this.userData.timings && this.userData.timings.find(x => x.day === key) ? this.userData.timings.find(x => x.day === key)[tag] : '00:00'
    },
    isTimeEnabled(key) {
      return this.userData.timings && Boolean(this.userData.timings.find(x => x.day === key))
    },
    openingTimeChanged() {
      if (!this.userData.everyday) {
        this.showTimings = false
      } else {
        this.showTimings = true
      }
    },
  },
}
</script>

<style>
.user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.profile-img-height {
  height: 16px;
}
</style>
